import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';
import ButtonSuggestions from './ButtonSuggestions';
import Sidebar from './Sidebar';
import { BsPencilSquare } from 'react-icons/bs';

const Chatbot = () => {
  const [suggestions] = useState([
    { text: 'What are you capable of?', icon: <img src={`${process.env.PUBLIC_URL}/Dime/icons10.png`} alt="What are you capable of?" style={{ width: '38px', height: '38px' }} />, color: 'text-blue-500' },
    { text: 'Help buy a product today', icon: <img src={`${process.env.PUBLIC_URL}/Dime/icons30.png`} alt="Help buy a product today" style={{ width: '50px', height: '50px' }} />, color: 'text-yellow-500' },
    { text: 'Why buy precious metals?', icon: <img src={`${process.env.PUBLIC_URL}/Dime/icons0.png`} alt="Why buy precious metals?" style={{ width: '40px', height: '40px' }} />, color: 'text-red-500' },
  ]);

  const [shouldHide, setShouldHide] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [key, setKey] = useState(0);
  const messengerRef = useRef(null);

  useEffect(() => {
    const handleMessengerLoaded = () => {
      messengerRef.current = document.querySelector('df-messenger');

      const modifyChatUI = () => {
        const shadowRoot = messengerRef.current.shadowRoot;
        if (shadowRoot) {
          const dfMessengerChatSlot = shadowRoot.querySelector('slot');
          if (dfMessengerChatSlot) {
            const assignedElements = dfMessengerChatSlot.assignedElements();
            const dfMessengerChat = assignedElements.find(el => el.tagName.toLowerCase() === 'df-messenger-chat');
            if (dfMessengerChat && dfMessengerChat.shadowRoot) {
              const chatShadowRoot = dfMessengerChat.shadowRoot;

              const titleBar = chatShadowRoot.querySelector('df-messenger-titlebar');
              if (titleBar) {
                titleBar.remove();
              }

              const chatWrapper = chatShadowRoot.querySelector('div.chat-wrapper');
              if (chatWrapper) {
                const messageListWrapper = chatWrapper.querySelector('div.message-list-wrapper');
                if (messageListWrapper) {
                  messageListWrapper.style.textAlign = 'left';
                }
                chatWrapper.style.border = 'none';

                const dfMessengerMessageList = chatWrapper.querySelector('df-messenger-message-list');
                if (dfMessengerMessageList && dfMessengerMessageList.shadowRoot) {
                  const messageListShadowRoot = dfMessengerMessageList.shadowRoot;
                  const messageList = messageListShadowRoot.querySelector('div#message-list');
                  if (messageList) {
                    messageList.style.overflow = 'auto';
                    messageList.style.position = 'absolute';
                    messageList.style.top = '0';
                    messageList.style.right = '0';
                    messageList.style.bottom = '0';
                    messageList.style.left = '0';

                    const styleSheet = document.createElement('style');
                    styleSheet.textContent = `
                      * {
                        scrollbar-width: thin;
                        scrollbar-color: #d3d3d3 #ffffff;
                        scrollbar-width: none;
                      }
                    `;
                    messageListShadowRoot.appendChild(styleSheet);

                    const checkForBotMessages = () => {
                      const botMessages = messageList.querySelectorAll('.entry.bot');
                      setShouldHide(botMessages.length > 0);
                    };

                    checkForBotMessages();

                    const observer = new MutationObserver(() => {
                      checkForBotMessages();
                    });

                    observer.observe(messageList, {
                      childList: true,
                      subtree: true,
                    });

                    const applyCustomCSS = () => {
                      const dfMessengerUtterances = messageList.querySelectorAll('df-messenger-utterance');

                      dfMessengerUtterances.forEach((utterance) => {
                        if (utterance.shadowRoot) {
                          const utteranceShadowRoot = utterance.shadowRoot;

                          const dfMarkdownMessage = utteranceShadowRoot.querySelector('df-markdown-message');
                          if (dfMarkdownMessage) {
                            const styleMarkdown = document.createElement('style');
                            styleMarkdown.textContent = `
                              @media (max-width: 768px) {
                                df-markdown-message{
                                  margin-right: 0 !important;
                                  max-width: 100% !important;
                                }
                              }
                              a {
                                text-decoration: none !important;
                                border: none !important;
                                outline: none !important;
                              }
                            `;
                            dfMarkdownMessage.shadowRoot.appendChild(styleMarkdown);
                          }

                          const dfChips = utteranceShadowRoot.querySelector('df-chips');
                          if (dfChips && dfChips.shadowRoot) {
                            const chipsShadowRoot = dfChips.shadowRoot;

                            const chipTextElements = chipsShadowRoot.querySelectorAll('.text.word-wrap');

                            chipTextElements.forEach((element) => {
                              const styleChips = document.createElement('style');
                              styleChips.textContent = `
                                .text.word-wrap {
                                  word-break: break-word !important;
                                  overflow-wrap: break-word !important;
                                  white-space: normal !important;
                                  text-align: center !important;
                                }
                              `;
                              chipsShadowRoot.appendChild(styleChips);
                            });
                          }
                        }
                      });
                    };

                    applyCustomCSS();

                    const observerCSS = new MutationObserver((mutations) => {
                      mutations.forEach((mutation) => {
                        if (mutation.type === 'childList') {
                          applyCustomCSS();
                        }
                      });
                    });

                    observerCSS.observe(messageList, {
                      childList: true,
                      subtree: true,
                    });

                    const dfMessengerUserInput = chatShadowRoot.querySelector('df-messenger-user-input');
                    if (dfMessengerUserInput && dfMessengerUserInput.shadowRoot) {
                      const inputShadowRoot = dfMessengerUserInput.shadowRoot;
                      const inputElement = inputShadowRoot.querySelector('.input-container');
                      if (inputElement) {
                        inputElement.style.paddingBottom = 'env(safe-area-inset-bottom)';
                      }
                    }

                  }
                }
              }
            }
          }
        }
      };

      modifyChatUI();
      window.addEventListener('df-request-sent', handleRequestSent);
    };

    const loadDialogflowMessenger = () => {
      if (!document.querySelector('script[src="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js"]')) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/themes/df-messenger-default.css';
        document.head.appendChild(link);

        const script = document.createElement('script');
        script.src = 'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
          window.dispatchEvent(new Event('df-messenger-loaded'));
        };
      } else {
        window.dispatchEvent(new Event('df-messenger-loaded'));
      }
    };

    const handleRequestSent = (event) => {
      setShouldHide(true);
    };

    window.addEventListener('df-messenger-loaded', handleMessengerLoaded);
    loadDialogflowMessenger();

    return () => {
      window.removeEventListener('df-messenger-loaded', handleMessengerLoaded);
      window.removeEventListener('df-request-sent', handleRequestSent);
    };
  }, []);

  const handleSuggestionSelect = (text) => {
    setShouldHide(true);
    if (messengerRef.current) {
      const shadowRoot = messengerRef.current.shadowRoot;

      const dfMessengerChatSlot = shadowRoot.querySelector('slot');
      if (dfMessengerChatSlot) {
        const assignedElements = dfMessengerChatSlot.assignedElements();
        const dfMessengerChat = assignedElements.find(el => el.tagName.toLowerCase() === 'df-messenger-chat');
        if (dfMessengerChat && dfMessengerChat.shadowRoot) {
          const chatShadowRoot = dfMessengerChat.shadowRoot;

          const dfMessengerUserInput = chatShadowRoot.querySelector('df-messenger-user-input');
          if (dfMessengerUserInput && dfMessengerUserInput.shadowRoot) {

            const inputElement = dfMessengerUserInput.shadowRoot.querySelector('textarea.input-box');
            if (inputElement) {
              const inputEvent = new Event('input', { bubbles: true });
              const enterEvent = new KeyboardEvent('keydown', { bubbles: true, key: 'Enter', keyCode: 13 });

              inputElement.value = text;
              inputElement.dispatchEvent(inputEvent);
              inputElement.dispatchEvent(enterEvent);
            }
          }
        }
      }
    }
  };

  const handleNewSession = () => {
    if (messengerRef.current) {
      setIsFading(true);
      setKey(prevKey => prevKey + 1);
      setTimeout(() => {
        messengerRef.current.startNewSession({ retainHistory: false });
        setShouldHide(false);
        localStorage.removeItem('shouldHide');
        setTimeout(() => {
          setIsFading(false);
        }, 50);
      }, 50);
    }
  };

  return (
    <div className="h-screen flex flex-col relative overflow-hidden">
      <div className="hidden lg:block">
        <Sidebar onNewConversation={handleNewSession} className="sidebar" />
      </div>
      <div className="lg:hidden fixed top-4 right-4 z-20">
        <button
          onClick={handleNewSession}
          className="bg-white text-gray-700"
        >
          <BsPencilSquare className="w-6 h-6" />
        </button>
      </div>
      <ButtonSuggestions
        key={key}
        suggestions={suggestions}
        onSelect={handleSuggestionSelect}
        shouldHide={shouldHide}
        isFading={isFading}
      />
      <div className="flex-grow relative z-0 pt-16 lg:pt-0 custom-scroll-container">
        <df-messenger
          location="us-central1"
          project-id="dialogflowtutorial-lnhc"
          agent-id="a9cd39a2-66f9-4330-8608-08aad1ff6def"
          language-code="en"
          max-query-length="-1">
          <df-messenger-chat
            chat-title=""
            allow-fullscreen="always"
            id="df-chat-wrapper">
          </df-messenger-chat>
        </df-messenger>
      </div>
    </div>
  );
};

export default Chatbot;
