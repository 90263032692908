import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { BsPencilSquare } from 'react-icons/bs';

const Sidebar = ({ onNewConversation }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isIconVisible, setIsIconVisible] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsIconVisible(true);
    } else {
      const timer = setTimeout(() => {
        setIsIconVisible(false);
      }, 500); // Match the duration of the transition
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleNewConversationClick = () => {
    onNewConversation();
  };

  return (
<div className={`fixed top-0 left-0 h-full transition-all duration-500 ${isOpen ? 'w-64' : 'w-16'} bg-gray-100 z-50`}>
  <div className="flex items-center justify-between px-4 pt-4 pb-1">
    <div className={`select-none cursor-default transition-all duration-500 ease-in-out whitespace-nowrap ${isOpen ? 'translate-x-0 opacity-100' : '-translate-x-20 opacity-0'}`}>
      <img 
        src={`${process.env.PUBLIC_URL}/Dime/dime0.png`} 
        alt="DIME AI Logo" 
        className="transition-all duration-500 ease-in-out transform scale-100" 
        style={{ height: isOpen ? '3.5rem' : '3rem' }} 
      />
    </div>
  </div>
      <div className="flex flex-col items-start px-4"> {/* Reduced margin-top */}
        <button
          className={`flex items-center w-full p-1.5 text-left rounded-lg select-none cursor-default hover:bg-gray-200 transition-all duration-500 ease-in-out whitespace-nowrap ${isOpen ? 'translate-x-0 opacity-100' : '-translate-x-20 opacity-0'}`} /* Reduced margin-top and margin-bottom */
          onClick={handleNewConversationClick}
        >
          <BsPencilSquare className={`w-5 h-5 mr-3 text-gray-700 transition-all duration-500 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`} />
          <span className={`text-base text-gray-700 transition-all duration-500 ease-in-out ${isOpen ? 'translate-x-0 opacity-100' : '-translate-x-20 opacity-0'}`}>New conversation</span>
        </button>
        {!isOpen && (
          <button
            className="absolute top-4 left-1/2 transform -translate-x-1/2 p-2 bg-gray-100 rounded-full transition-opacity duration-500 ease-in-out"
            onClick={handleNewConversationClick}
            style={{ opacity: isIconVisible ? 1 : 0 }}
          >
            <BsPencilSquare className="w-6 h-6 text-gray-700" />
          </button>
        )}
      </div>
      <button
        onClick={handleToggleSidebar}
        className={`absolute left-0 transition-all duration-400 ease-in-out select-none cursor-default text-gray-700 ${isOpen ? 'hidden' : 'block'} w-10 h-10 flex items-center justify-center`}
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      >
        <FaChevronRight className="w-6 h-6" />
      </button>
      <button
        onClick={handleToggleSidebar}
        className={`absolute left-0 transition-all duration-400 ease-in-out select-none cursor-default text-gray-700 ${isOpen ? 'block' : 'hidden'} w-10 h-10 flex items-center justify-center`}
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      >
        <FaChevronLeft className="w-6 h-6" />
      </button>
    </div>
  );
};

export default Sidebar;
