import React, { useState, useEffect } from 'react';

const ButtonSuggestions = ({ suggestions, onSelect, shouldHide, isFading }) => {
  const [visible, setVisible] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  useEffect(() => {
    const handleMessengerLoaded = () => setVisible(true);
    window.addEventListener('df-messenger-loaded', handleMessengerLoaded);

    return () => {
      window.removeEventListener('df-messenger-loaded', handleMessengerLoaded);
    };
  }, []);

  useEffect(() => {
    const updateSuggestions = () => {
      if (window.innerWidth <= 768) {
        setFilteredSuggestions(suggestions.slice(0, 2));
      } else {
        setFilteredSuggestions(suggestions);
      }
    };

    updateSuggestions();
    window.addEventListener('resize', updateSuggestions);

    return () => {
      window.removeEventListener('resize', updateSuggestions);
    };
  }, [suggestions]);

  useEffect(() => {
    if (!shouldHide) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [shouldHide]);

  useEffect(() => {
    const elements = document.querySelectorAll('.staggered-fade-in');
    elements.forEach((element) => {
      console.log('Attaching animationend listener to:', element);
      element.addEventListener('animationend', (event) => {
        console.log('Animation ended for:', event.target);
        element.classList.remove('staggered-fade-in');
        element.classList.add('hover-scale');
      });
    });
  }, [filteredSuggestions]);  // Ensure this runs when suggestions change

  const handleClick = (text) => {
    onSelect(text);
  };

  return (
    <div className={`fixed top-1/3 left-1/2 transform -translate-x-1/2 flex justify-center items-center flex-col ${visible ? 'block' : 'hidden'} ${isFading ? 'opacity-0 transition-opacity duration-150 ease-in-out' : 'opacity-100 transition-opacity duration-150 ease-in-out'} z-10`}>
      <div className="title text-xl font-semibold mb-4 select-none cursor-default fade-in-up" style={{ animationDuration: '1s' }}>Ask Dime AI Anything</div>
      <div className="flex justify-center flex-wrap gap-4">
        {filteredSuggestions.map((suggestion, index) => (
          <button
            key={index}
            className="bg-white border border-gray-300 select-none cursor-default rounded-lg shadow-lg p-4 flex items-center justify-between w-64 staggered-fade-in"
            style={{ '--animation-delay': `${(index + 1) * 0.3}s` }}
            onClick={() => handleClick(suggestion.text)}
          >
            <span className="flex-grow text-gray-700">{suggestion.text}</span>
            <span className={`text-2xl ml-2 ${suggestion.color}`}>{suggestion.icon}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ButtonSuggestions;
